import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import theme from "../themes/theme";
import { Typography, Box, useMediaQuery, Fade } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../styles/fontFaces.css";
import CloseIcon from "./icons/CloseIcon";
import InformationIcon from "./icons/InformationIcon";

const SurveyQuestionPopover = ({
  anchorEl,
  handleClose,
  title,
  description,
}) => {
  const [openState, setOpenState] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [anchorElement, setAnchorElement] = useState(anchorEl);
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (anchorEl) {
      setAnchorElement(anchorEl);
      setOpenState(true);
    } else {
      setOpenState(false);
    }
  }, [anchorEl]);

  const handleCloseWithDelay = () => {
    setClicked(!clicked);
    setOpenState(false);
    setTimeout(() => {
      handleClose();
      setAnchorElement(null);
    }, 500);
  };

  const getPopoverPosition = () => {
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();

      const topOffset = rect.top + window.scrollY;
      const leftOffset = rect.left + window.scrollX;

      return {
        top: topOffset - 150,
        left: leftOffset,
        transform: "translateX(-81%)",
      };
    }
    return { top: 0, left: 0, transform: "" };
  };

  const { top, left, transform } = getPopoverPosition();

  return ReactDOM.createPortal(
    <Fade in={openState} timeout={500}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: `${top}px`,
          left: `${left}px`,
          width: "100%",
          maxWidth: "300px",
          transform,
          zIndex: 1200,
          backgroundColor: theme.palette.lightPurple,
          boxShadow: theme.shadows[4],
        })}
      >
        <Box
          sx={{
            p: 3,
            maxHeight: "100px",
            borderTop: `1px solid ${theme.palette.tertiary.main}`,
            borderBottom: `1px solid ${theme.palette.tertiary.main}`,
            textAlign: "left",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InformationIcon sx={{ fontSize: 24, mx: 1 }} />
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.tertiary.main,
                  fontSize: 18,
                  mb: 1,
                  mt: 0.5,
                }}
              >
                {title}
              </Typography>
            </Box>
            {!isDesktop && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "32px",
                  height: "32px",
                  cursor: "pointer",
                  "&:focus": {
                    outline: `1px dashed ${theme.palette.tertiary.main}`,
                    outlineOffset: "-2px",
                  },
                }}
                tabIndex={0}
                onClick={handleCloseWithDelay}
              >
                <CloseIcon
                  ariaLabel={t("questionText.close")}
                  fill="inherit"
                  sx={{
                    fontSize: "20px",
                    color: "common.black",
                  }}
                />
              </Box>
            )}
          </Box>
          <Typography
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 14,
              mb: 1,
              ml: 1.2,
              textAlign: "left",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Fade>,
    document.body,
  );
};

SurveyQuestionPopover.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SurveyQuestionPopover;
