import React from "react";
import theme from "../themes/theme";
import PropTypes from "prop-types";
import { IconButton, useMediaQuery } from "@mui/material";
import InformationIcon from "./icons/InformationIcon";
import StyledTextField from "../styles/StyledTextField";
import SurveyQuestionPopover from "./SurveyQuestionPopover";
import { useController } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { usePopover } from "../hooks/usePopover";

const SurveyTextField = React.forwardRef(
  (
    {
      label,
      name,
      autoComplete,
      title,
      description,
      control,
      inputProps,
      maskFormat,
    },
    ref,
  ) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    const { activePopoverId, anchorEl, handlePopoverOpen, handlePopoverClose } =
      usePopover();
    const isPopoverOpen = activePopoverId === name;

    const eventHandlers = isDesktop
      ? {
          onMouseEnter: (event) => handlePopoverOpen(name, event),
          onMouseLeave: handlePopoverClose,
        }
      : {
          onClick: (event) => handlePopoverOpen(name, event),
        };

    const {
      field: { value, onChange, onBlur },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    return (
      <>
        {maskFormat ? (
          <PatternFormat
            ref={ref}
            value={value}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            onBlur={onBlur}
            customInput={StyledTextField}
            format={maskFormat}
            fullWidth
            label={label}
            name={name}
            autoComplete={autoComplete}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    disableRipple={true}
                    {...eventHandlers}
                    sx={{
                      ":focus": {
                        outline: `1px dashed ${theme.palette.tertiary.main}`,
                        outlineOffset: -3,
                        borderRadius: 70,
                      },
                    }}
                  >
                    <InformationIcon
                      sx={{ fontSize: "1.6rem", paddingTop: 0.4 }}
                    />
                  </IconButton>
                </>
              ),
              inputProps: inputProps,
              sx: {
                color: error ? theme.palette.secondary.main : "inherit",
                fontSize: 16,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: error ? theme.palette.darkRed : "inherit",
                  },
                },
              },
            }}
            sx={{
              my: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: 16,
                color:
                  error && value
                    ? theme.palette.darkRed
                    : error
                      ? theme.palette.neutralGray
                      : value
                        ? theme.palette.primary.main
                        : theme.palette.neutralGray,
                "&.Mui-focused": {
                  color: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
            }}
            InputLabelProps={{ sx: { fontSize: 16 } }}
          />
        ) : (
          <StyledTextField
            fullWidth
            label={label}
            name={name}
            autoComplete={autoComplete}
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    disableRipple={true}
                    {...eventHandlers}
                    sx={{
                      ":focus": {
                        outline: `1px dashed ${theme.palette.tertiary.main}`,
                        outlineOffset: -3,
                        borderRadius: 70,
                      },
                    }}
                  >
                    <InformationIcon
                      sx={{ fontSize: "1.6rem", paddingTop: 0.4 }}
                    />
                  </IconButton>
                </>
              ),
              inputProps: inputProps,
              sx: {
                color: error ? theme.palette.secondary.main : "inherit",
                fontSize: 16,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: error ? theme.palette.darkRed : "inherit",
                  },
                },
              },
            }}
            sx={{
              my: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: 16,
                color:
                  error && value
                    ? theme.palette.darkRed
                    : error
                      ? theme.palette.neutralGray
                      : value
                        ? theme.palette.primary.main
                        : theme.palette.neutralGray,
                "&.Mui-focused": {
                  color: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
            }}
            InputLabelProps={{ sx: { fontSize: 16 } }}
          />
        )}

        <SurveyQuestionPopover
          anchorEl={isPopoverOpen ? anchorEl : null}
          handleClose={handlePopoverClose}
          description={description}
          title={title}
        />
      </>
    );
  },
);
SurveyTextField.displayName = "SurveyTextField";

SurveyTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  description: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  maskFormat: PropTypes.string,
};

export default SurveyTextField;
