import { createContext, useState } from "react";
import PropTypes from "prop-types";

const PopoverContext = createContext();

export const PopoverProvider = ({ children }) => {
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (id, event) => {
    setActivePopoverId(id);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setActivePopoverId(null);
    setAnchorEl(null);
  };

  return (
    <PopoverContext.Provider
      value={{
        activePopoverId,
        anchorEl,
        handlePopoverOpen,
        handlePopoverClose,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

PopoverProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PopoverContext;
