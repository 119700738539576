import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const CloseIcon = ({ enableHover, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox="20 8 25 25"
      sx={{
        "&:hover path": {
          fill: enableHover ? "currentColor" : undefined,
        },
      }}
    >
      <path
        d="M42.2461 28.8242C42.3242 28.9023 42.3828 28.987 42.4219 29.0781C42.474 29.1823 42.5 29.293 42.5 29.4102C42.5 
        29.5273 42.474 29.6315 42.4219 29.7227C42.3828 29.8268 42.3242 29.918 42.2461 29.9961C42.168 30.0742 42.0768 30.1328 
        41.9727 30.1719C41.8815 30.224 41.7773 30.25 41.6602 30.25C41.543 30.25 41.4323 30.224 41.3281 30.1719C41.237 30.1328 
        41.1523 30.0742 41.0742 29.9961L32.5 21.4219L23.9258 29.9961C23.8477 30.0742 23.7565 30.1328 23.6523 30.1719C23.5612 
        30.224 23.457 30.25 23.3398 30.25C23.2227 30.25 23.112 30.224 23.0078 30.1719C22.9167 30.1328 22.832 30.0742 22.7539 
        29.9961C22.6758 29.918 22.6107 29.8268 22.5586 29.7227C22.5195 29.6315 22.5 29.5273 22.5 29.4102C22.5 29.293 22.5195 
        29.1823 22.5586 29.0781C22.6107 28.987 22.6758 28.9023 22.7539 28.8242L31.3281 20.25L22.7539 11.6758C22.6758 11.5977 
        22.6107 11.513 22.5586 11.4219C22.5195 11.3177 22.5 11.207 22.5 11.0898C22.5 10.9727 22.5195 10.8685 22.5586 10.7773C22.6107 
        10.6732 22.6758 10.582 22.7539 10.5039C22.832 10.4258 22.9167 10.3672 23.0078 10.3281C23.112 10.276 23.2227 10.25 23.3398 
        10.25C23.457 10.25 23.5612 10.276 23.6523 10.3281C23.7565 10.3672 23.8477 10.4258 23.9258 10.5039L32.5 19.0781L41.0742 
        10.5039C41.1523 10.4258 41.237 10.3672 41.3281 10.3281C41.4323 10.276 41.543 10.25 41.6602 10.25C41.7773 10.25 41.8815 
        10.276 41.9727 10.3281C42.0768 10.3672 42.168 10.4258 42.2461 10.5039C42.3242 10.582 42.3828 10.6732 42.4219 10.7773C42.474 10.8685 
        42.5 10.9727 42.5 11.0898C42.5 11.207 42.474 11.3177 42.4219 11.4219C42.3828 11.513 42.3242 11.5977 42.2461 11.6758L33.6719 
        20.25L42.2461 28.8242Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

CloseIcon.propTypes = {
  enableHover: PropTypes.bool,
};

CloseIcon.defaultProps = {
  enableHover: false,
};

export default CloseIcon;
