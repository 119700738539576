import CloseIcon from "./icons/CloseIcon";
import {
  Box,
  Button,
  Typography,
  Container,
  Link,
  IconButton,
  useTheme,
} from "@mui/material";
import cookie from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckmarkDialogueIcon from "./icons/CheckmarkDialogueIcon";
import { replaceMainSiteLinkHostname } from "../services/Utils";
import { COOKIE_CONSENT_KEY, DOMAIN } from "../configs/googleAnalyticsConfig";

const CookieConsentBanner = () => {
  const theme = useTheme();
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();

  const updateGTMConsent = (
    adStorage,
    analyticsStorage,
    adUserData,
    adPersonalization,
  ) => {
    window.gtag("consent", "update", {
      ad_storage: adStorage,
      analytics_storage: analyticsStorage,
      ad_user_data: adUserData,
      ad_personalization: adPersonalization,
    });
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    window.gtag =
      window.gtag ||
      function () {
        window.dataLayer.push(arguments);
      };

    const consentCookie = cookie.get(COOKIE_CONSENT_KEY);

    if (consentCookie === "granted") {
      // Directly set consent state to "granted"
      updateGTMConsent("granted", "granted", "granted", "granted");
    } else if (consentCookie === "denied") {
      // Directly set consent state to "denied"
      updateGTMConsent("denied", "denied", "denied", "denied");
    } else {
      // Show banner if no consent cookie is set
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    setShowBanner(false);

    // Set cookie to granted
    cookie.set(COOKIE_CONSENT_KEY, "granted", {
      expires: 365,
      domain: DOMAIN,
    });

    // Update GTM consent to "granted"
    updateGTMConsent("granted", "granted", "granted", "granted");

    // Log a consent event via gtag
    window.gtag("event", "cookie_consent", {
      consent_status: "granted",
    });
  };

  const handleReject = () => {
    setShowBanner(false);

    // Set cookie to denied
    cookie.set(COOKIE_CONSENT_KEY, "denied", {
      expires: 365,
      domain: DOMAIN,
    });

    // Update GTM consent to "denied"
    updateGTMConsent("denied", "denied", "denied", "denied");

    // Log a consent event via gtag
    window.gtag("event", "cookie_consent", {
      consent_status: "denied",
    });
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: theme.palette.secondary.dark,
        pb: 3,
        zIndex: 1300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0 -1px 2px 0 rgba(0, 0, 0, 0.25)",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2.5,
            mb: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckmarkDialogueIcon
              fill={theme.palette.common.white}
              sx={{ fontSize: 28, mt: 0.4 }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: theme.palette.common.white,
              }}
            >
              {t("cookieConsentBanner.title")}
            </Typography>
          </Box>
          <IconButton
            disableRipple={true}
            sx={{
              color: theme.palette.common.white,
              backgroundColor: "transparent",
              "&:hover": {
                color: theme.palette.pink300,
              },
              "&:focus": {
                outline: `1px dashed ${theme.palette.pink300}`,
                outlineOffset: "-6px",
                borderRadius: 0,
                color: theme.palette.pink300,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon enableHover={true} />
          </IconButton>
        </Box>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.common.white, lineHeight: 1.8 }}
        >
          {t("cookieConsentBanner.message")}
          <Link
            href={replaceMainSiteLinkHostname(t("footer.links.privacy.url"))}
            target="_blank"
            sx={{
              color: theme.palette.common.white,
              textDecoration: "underline",
              fontWeight: "bold",
              "&:hover": {
                textDecoration: "underline",
                color: theme.palette.pink300,
              },
              "&:focus": {
                textDecoration: "underline",
                bgcolor: "transparent",
                outline: `1px dashed ${theme.palette.pink300}`,
                outlineOffset: "4px",
                borderRadius: 0,
                color: theme.palette.pink300,
              },
            }}
          >
            {t("cookieConsentBanner.privacyPolicy")}
          </Link>
          .
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "right", gap: 1 }}>
          <Button
            variant="text"
            onClick={handleAccept}
            disableRipple={true}
            sx={{
              fontSize: 16,
              color: theme.palette.primary.light,
              textTransform: "none",
              fontWeight: "bold",
              bgcolor: "transparent",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                bgcolor: "transparent",
                color: theme.palette.pink300,
              },
              "&:focus": {
                textDecoration: "underline",
                bgcolor: "transparent",
                outline: `1px dashed ${theme.palette.pink300}`,
                outlineOffset: "-5px",
                borderRadius: 0,
                color: theme.palette.pink300,
              },
            }}
          >
            {t("cookieConsentBanner.accept")}
          </Button>
          <Button
            variant="text"
            onClick={handleReject}
            disableRipple={true}
            sx={{
              fontSize: 16,
              color: theme.palette.common.white,
              textTransform: "none",
              fontWeight: "bold",
              bgcolor: "transparent",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                bgcolor: "transparent",
                color: theme.palette.pink300,
              },
              "&:focus": {
                textDecoration: "underline",
                bgcolor: "transparent",
                outline: `1px dashed ${theme.palette.pink300}`,
                outlineOffset: "-5px",
                borderRadius: 0,
                color: theme.palette.pink300,
              },
            }}
          >
            {t("cookieConsentBanner.reject")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CookieConsentBanner;
