import { Container, useMediaQuery, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import CookieConsentBanner from "./components/CookieConsentBanner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import i18n from "./i18n";
import SurveyForm from "./components/SurveyForm";
import theme from "./themes/theme";

import "./styles/global.css";

function App() {
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const filteredParams = {
    tos: searchParams.get("tos"),
    ua: searchParams.get("ua"),
    trv: searchParams.get("trv"),
    surveyID: searchParams.get("surveyID"),
  };
  const interactionIdKey = location.pathname.substring(1) || undefined;

  useEffect(() => {
    // Clear local storage on load to reset Genesys
    window.localStorage.clear();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CookieConsentBanner />
        <I18nextProvider i18n={i18n}>
          <GlobalStyles
            styles={{
              body: {
                backgroundColor: isDesktop ? theme.palette.slateGray : "white",
              },
            }}
          />
          <Header />
          <Container
            maxWidth="md"
            disableGutters
            sx={{ bgcolor: "white", borderRadius: 3 }}
          >
            <Container
              maxWidth="sm"
              disableGutters
              sx={{
                mt: isDesktop ? 6 : 2,
                pt: isDesktop ? 1 : 0,
                mb: isDesktop ? 13 : 0,
                pb: 1.5,
                px: 1.5,
                bgcolor: "white",
              }}
            >
              <SurveyForm
                interactionIdKey={interactionIdKey}
                filteredParams={filteredParams}
              />
            </Container>
          </Container>
          <Footer />
        </I18nextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
