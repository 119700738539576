import {
  AppBar,
  Button,
  Box,
  Menu,
  MenuItem,
  Link,
  List,
  ListItem,
  useScrollTrigger,
} from "@mui/material";
import { useState } from "react";

import useLanguage from "../hooks/useLanguage";
import ASLIcon from "./icons/ASLIcon";
import CallIcon from "./icons/CallIcon";
import CheckmarkIcon from "./icons/CheckmarkIcon";
import DropDownIcon from "./icons/DropdownIcon";
import TextIcon from "./icons/TextIcon";
import LanguageIcon from "./icons/LanguageIcon";
import NineEightEightSuicideCrisisLifelineLogoEN from "./icons/NineEightEightSuicideCrisisLifelineLogoEN";
import NineEightEightSuicideCrisisLifelineLogoES from "./icons/NineEightEightSuicideCrisisLifelineLogoES";
import { replaceMainSiteLinkHostname } from "../services/Utils";

export default function Header() {
  const { t, language } = useLanguage();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <AppBar
      position="sticky"
      sx={(theme) => ({
        bgcolor: theme.palette.secondary.light,
        boxShadow: "none",
        borderBottom: trigger ? "1px solid #0c172e4d" : "none",
      })}
    >
      <nav>
        <List
          sx={{
            m: 0,
            p: 0,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            columnGap: "1.6rem",
            rowGap: "0",
          }}
        >
          <ListItem
            disablePadding
            sx={{ width: "initial", height: "46.4px", margin: 0 }}
          >
            <Link
              href="tel:988"
              variant="top-nav"
              aria-label="call"
              sx={(theme) => ({
                position: "relative",
                ":focus::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  border: `1px dashed ${theme.palette.tertiary.main}`,
                  pointerEvents: "none",
                },
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                padding: "0 8px",
                lineHeight: "1",
              })}
            >
              <CallIcon />
              {t("navigationMenu.call")}
            </Link>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ width: "initial", height: "46.4px", margin: 0 }}
          >
            <Link
              href="sms:988"
              variant="top-nav"
              aria-label="text"
              sx={(theme) => ({
                position: "relative",
                ":focus::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  border: `1px dashed ${theme.palette.tertiary.main}`,
                  pointerEvents: "none",
                },
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
                padding: "0 8px",
                lineHeight: "1",
              })}
            >
              <TextIcon />
              {t("navigationMenu.text")}
            </Link>
          </ListItem>
          {language !== "es" && (
            <ListItem
              disablePadding
              sx={{ width: "initial", height: "46.4px", margin: 0 }}
            >
              <Link
                variant="top-nav"
                href={t("navigationMenu.aslLink")}
                target="_blank"
                rel="noopener"
                aria-label="asl now"
                sx={(theme) => ({
                  position: "relative",
                  ":focus::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    border: `1px dashed ${theme.palette.tertiary.main}`,
                    pointerEvents: "none",
                  },
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                  padding: "0 8px",
                  lineHeight: "1",
                })}
              >
                <ASLIcon />
                {t("navigationMenu.aslNow")}
              </Link>
            </ListItem>
          )}
        </List>
      </nav>
      <SubNav />
    </AppBar>
  );
}

function SubNav() {
  const { t, language } = useLanguage();

  return (
    <nav>
      <List
        sx={(theme) => ({
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "white",
          py: 1.2,
          px: 0.8,
          [theme.breakpoints.up("sm")]: {
            px: 5.8,
          },
          [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            py: 2.6,
          },
        })}
      >
        <ListItem
          disablePadding
          sx={(theme) => ({
            width: "initial",
            display: "none",
            [theme.breakpoints.up("md")]: {
              display: "block",
            },
          })}
        >
          <Link
            href={replaceMainSiteLinkHostname(t("navigationMenu.home.url"))}
            target="_blank"
            rel="noopener"
            underline="hover"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              textDecoration: "underline",
              textTransform: "none",
              fontFamily: "Public Sans",
              fontSize: "1rem",
              fontWeight: "bold",
              "&:hover": {
                color: theme.palette.tertiary.main,
              },
              ":focus": {
                outline: `1px dashed ${theme.palette.pink300}`,
                outlineOffset: 4,
              },
            })}
          >
            {t("navigationMenu.home.label")}
          </Link>
        </ListItem>
        <ListItem
          disablePadding
          sx={(theme) => ({
            width: "initial",
            [theme.breakpoints.up("md")]: {
              placeSelf: "center",
            },
          })}
        >
          <Link
            href={replaceMainSiteLinkHostname(t("navigationMenu.home.url"))}
            target="_blank"
            rel="noopener"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              ":hover": {
                color: theme.palette.primary.main,
              },
              ":focus": {
                outline: `1px dashed ${theme.palette.tertiary.main}`,
                outlineOffset: 4,
              },
            })}
          >
            {language.startsWith("en") ? (
              <NineEightEightSuicideCrisisLifelineLogoEN
                fill="inherit"
                sx={(theme) => ({
                  width: "100%",
                  height: "auto",
                  maxHeight: "38px",
                  [theme.breakpoints.up("tiny")]: {
                    width: "auto",
                    height: "38px",
                  },
                })}
              />
            ) : (
              <NineEightEightSuicideCrisisLifelineLogoES
                sx={{ fill: "primary.contrast", width: "auto", height: "38px" }}
              />
            )}
          </Link>
        </ListItem>
        <ListItem
          disablePadding
          sx={(theme) => ({
            width: "initial",
            [theme.breakpoints.up("md")]: {
              placeSelf: "center end",
            },
          })}
        >
          <LanguageSelector />
        </ListItem>
      </List>
    </nav>
  );
}

function LanguageSelector() {
  const { t, language, setLanguage } = useLanguage();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (newLanguage) => {
    if (newLanguage && newLanguage !== language) {
      setLanguage(newLanguage);
    }
    setAnchorEl(null);
  };

  const languageLabels = {
    en: t("navigationMenu.englishLabel"),
    "en-US": t("navigationMenu.englishLabel"),
    es: t("navigationMenu.spanishLabel"),
  };

  const uniqueLanguages = {
    en: t("navigationMenu.englishLabel"),
    es: t("navigationMenu.spanishLabel"),
  };

  const isCurrentLanguage = (lang) =>
    language === lang || (language.startsWith("en") && lang === "en");

  return (
    <>
      <Button
        onClick={handleLanguageClick}
        startIcon={<LanguageIcon />}
        endIcon={<DropDownIcon sx={{ ml: -1.5, mr: 1 }} />}
        disableRipple={true}
        sx={(theme) => ({
          textTransform: "none",
          color: theme.palette.primary.contrast,
          borderRadius: "999px",
          padding: "3px 10px",
          border: `1px solid ${theme.palette.primary.contrast}`,
          fontSize: ".85rem",
          width: "120px",
          ":focus": {
            outline: `1px dashed ${theme.palette.tertiary.main}`,
            outlineOffset: 4,
          },
        })}
      >
        {languageLabels[language] || languageLabels["en"]}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleLanguageClose()}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {Object.entries(uniqueLanguages).map(([lang, label]) => (
          <MenuItem
            sx={{
              height: 20,
              width: 110,
              display: "flex",
              alignItems: "center",
            }}
            key={lang}
            onClick={() => handleLanguageClose(lang)}
            disableRipple={true}
          >
            {isCurrentLanguage(lang) ? (
              <>
                <CheckmarkIcon fontSize="medium" />
                <Box sx={{ width: "5px" }} />
              </>
            ) : (
              <Box sx={{ width: "20px" }} />
            )}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
